import { ClientCard } from 'components/client-card';

import { Clienttype } from '../selfContainedComponents/clients/clients';

export type ClientGridProps = {
  clients: Clienttype[];
};

export function ClientsGrid({ clients }: ClientGridProps) {
  return (
    <div className="px-6 xl:px-12 flex gap-4 min-w-full flex-wrap flex-row">
      <ul className="mx-auto flex flex-row flex-wrap gap-4 justify-center">
        {clients.map((client) => (
          <li key={client.name} className="w-full relative min-[720px]:w-fit">
            <ClientCard {...client} />
          </li>
        ))}
      </ul>
    </div>
  );
}
