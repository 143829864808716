import classNames from 'classnames';
import type { ReactNode } from 'react';

interface TypographyProps {
  children: ReactNode;
  as?: (typeof elementTypes)[number];
  variant: keyof typeof variantMap;
  weight?: keyof typeof weightMap;
  color?: keyof typeof colorMap;
  className?: string;
}

export const variantMap = {
  h1: 'text-4xl lg:text-10xl',
  h1sub: 'text-sm lg:text-xl',
  h2: 'text-2xl lg:text-8xl',
  h2sub: 'text-xs lg:text-xl',
  h3: 'text-xl lg:text-5xl',
  'label-large': 'text-ui-2xl lg:text-ui-5xl',
  'label-regular': 'text-ui-md lg:text-ui-3xl',
  'label-small': 'text-ui-sm lg:text-ui-xl',
  lead: 'text-body-xl lg:text-body-3xl',
  paragraph: 'text-body-md lg:text-body-2xl',
  'paragraph-small': 'text-body-sm lg:text-body-xl',
  blockquote:
    'text-body-blockquote-small lg:text-body-blockquote font-semibold',
};

export const colorMap = {
  primary: 'text-neutral',
  secondary: 'text-neutral-600',
  muted: 'text-neutral-400',
  white: 'text-neutral-inverted',
  error: 'text-error',
};

export const weightMap = {
  bold: 'font-bold',
  semiBold: 'font-semibold',
  medium: 'font-medium',
};

export const elementTypes = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'p',
  'span',
  'blockquote',
  'label',
] as const;

export const Typography = ({
  children,
  as,
  variant,
  weight,
  color = 'primary',
  className,
}: TypographyProps) => {
  const Element =
    as ||
    elementTypes.find((el) => el === variant || variant.startsWith(el)) ||
    'span';

  const isHeading = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(Element);
  const defaultWeight = [
    'label-large',
    'label-regular',
    'label-small',
  ].includes(variant)
    ? 'bold'
    : 'medium';

  return (
    <Element
      className={classNames(
        variantMap[variant],
        colorMap[color],
        weightMap[weight || defaultWeight],
        {
          ['font-heading font-bold']: isHeading,
          ['font-paragraph']: !isHeading,
        },
        className
      )}
    >
      {children}
    </Element>
  );
};
