import classNames from 'classnames';

import { Icon } from '@/components/internalComponents/icon';
import { LinkButton } from '@/components/link-button';
import { ListItem, ListItemProps } from '@/components/list-item';
import { Typography } from '@/components/typography/typography';

export type BaseAccordionTileProps = {
  heading: string;
  leadingParagraph: string;
  list?: ListItemProps[];
  trailingParagraph?: string;
  link?: { href: string; label: string };
};

export type AccordionTileProps = {
  isActive?: boolean;
  onClick: () => void;
} & BaseAccordionTileProps;

export function AccordionTile({
  heading,
  leadingParagraph,
  list,
  trailingParagraph,
  link,
  isActive = false,
  onClick,
}: AccordionTileProps) {
  return (
    <div className="relative group">
      <button
        onClick={onClick}
        className={classNames(
          'w-full flex justify-between border-b border-neutral-400 !border-solid lg:border-0 py-8 lg:py-16 lg:px-[5.25rem] flex-col flex-between',
          'before:hidden before:absolute before:top-0 before:left-0 before:right-0 before:bottom-0 before:w-full before:h-full before:z-[-1]',
          'lg:before:flex before:justify-center before:items-center before:bg-surface-grey',
          'before:hover:w-[calc(100%+24px)] before:hover:h-[calc(100%+24px)] before:hover:left-[-12px] before:hover:top-[-12px] before:duration-[120ms] before:ease-out [&_label]:cursor-pointer',
          isActive && 'border-none'
        )}
      >
        <div className="flex flex-row justify-between w-full items-start">
          <Typography
            variant="label-large"
            weight="bold"
            className="text-left max-w-[80%] mr-6"
          >
            {heading}
          </Typography>
          <div
            className={classNames(
              'transition-transform ease-out duration-[120ms] h-fit',
              !isActive && 'rotate-45'
            )}
          >
            <div className="rounded-full border-2 border-neutral-600 p-[10px] lg:p-5">
              <Icon
                name="Cross"
                className={classNames(
                  'min-w-6 min-h-6 w-6 !h-6 lg:min-w-10 lg:min-h-10 fill-neutral-800'
                )}
              />
            </div>
          </div>
        </div>
        <div
          className={classNames(
            'gap-5 lg:gap-7 transition-all ease-out duration-[120ms] [&>*]:text-left',
            'grid overflow-hidden transition-[grid-template-rows] h-full',
            isActive ? 'grid-rows-[1fr] pb-6' : 'grid-rows-[0fr]'
          )}
        >
          <div className="min-h-0 [&_p]:text-neutral-600 ">
            <Typography variant="paragraph" className="pt-5">
              {leadingParagraph}
            </Typography>
            {list && (
              <ul className="[&_span]:!text-neutral-600">
                {list.map((item, index) => (
                  <ListItem
                    key={`accordionTileListItem${index}`}
                    leadingAddon={<Icon name="Checkmark" />}
                    {...item}
                  />
                ))}
              </ul>
            )}
            {trailingParagraph && (
              <Typography variant="paragraph">{trailingParagraph}</Typography>
            )}
            {link && (
              <LinkButton
                href={link.href}
                label={link.label}
                trailingIcon="ArrowRight"
                className="mt-7"
              />
            )}
          </div>
        </div>
      </button>
    </div>
  );
}
