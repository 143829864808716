import { FilterType } from '@/types/misc';
import { getPostsV2 } from '@/helpers/api/getPosts';
import { BlogPosts } from '@/components/selfContainedComponents/blogPosts/blogPosts';
import { PostV2 } from '@/types/post';
import { TileList } from '@/types/tileProps';

export interface BlogPostsServerProps {
  filters?: FilterType[];
  maxPerRow?: number;
  category: string;
  page: string;
}

export const mapPostTiles = (posts: PostV2[]): TileList[] => {
  return posts.map((post: PostV2) => ({
    id: post.id.toString(),
    image: post.attributes.thumbnail.data?.attributes.url,
    title: post.attributes.title,
    category: post.attributes.category,
    date: post.attributes.publishDate,
    href: `/nieuws${post.attributes.slug}`,
  }));
};

export const BlogPostsServer = async ({
  filters,
  category: initialCategory,
  page: initialPage,
}: BlogPostsServerProps) => {
  const postsFilters =
    initialCategory && initialCategory !== 'alle'
      ? {
          category: {
            $eqi: initialCategory,
          },
        }
      : undefined;
  const postsPageIndex = initialPage ? Number(initialPage) : 1;

  const posts = await getPostsV2({
    params: {
      sort: 'publishDate:desc',
      pagination: {
        pageSize: 10,
        page: postsPageIndex,
      },
      populate: {
        thumbnail: {
          fields: ['url'],
        },
      },
      filters: postsFilters,
    },
  });
  const tiles = mapPostTiles(posts.data);

  return (
    <BlogPosts
      filters={filters}
      currentFilter={initialCategory ?? 'alle'}
      pageCount={posts.pageCount}
      currentPage={postsPageIndex}
      tiles={tiles}
    />
  );
};
