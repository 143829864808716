'use client';
import { useState } from 'react';
import {
  AccordionTile,
  BaseAccordionTileProps,
} from './accordionTile/accordionTile';

export type AccordionProps = {
  tiles: BaseAccordionTileProps[];
};

export function Accordion({ tiles }: AccordionProps) {
  const [activeTileIndex, setActiveTileIndex] = useState(-1);

  const handleTileClick = (index: number) => {
    setActiveTileIndex((previousTileIndex) =>
      previousTileIndex === index ? -1 : index
    );
  };

  return (
    <div className="flex flex-col lg:gap-8">
      {tiles.map(({ ...tile }, index) => (
        <AccordionTile
          key={`accordionTile${index}`}
          isActive={index === activeTileIndex}
          onClick={() => handleTileClick(index)}
          {...tile}
        />
      ))}
    </div>
  );
}
